<template>
    <v-row no-gutters justify="center" align="center">
        <v-col cols="12" align="center">
            <v-card class="rounded-lg px-3" width="1000px">
                <v-row align="center">
                    <v-col cols="6" align="end">
                        <img :width="!$vuetify.breakpoint.mobile ? '70px' : '70px'" contain :src="require('@/assets/contact-message-icon.svg')">
                    </v-col>
                    <v-col cols="6" align="start">
                        <span class="font-weight-bold text-h6">Στοιχεία Επικοινωνίας</span>
                    </v-col>
                </v-row>
                <v-row align="start">
                    <v-col cols="6" align="end">
                        <span class="font-weight-bold text-h6 custom-orange-text">Κινητό: </span>
                        <span class="font-weight-bold text-h6">+306979575906</span>
                    </v-col>
                    <v-col cols="6" align="start">
                        <span class="font-weight-bold text-h6 custom-orange-text">Email: </span>
                        <span class="font-weight-bold text-h6">ivalmas@backinthegame.gr</span>
                    </v-col>
                </v-row>
                <v-row align="start">
                    <v-col cols="12" align="center">
                        <v-divider
                            horizontal
                            color="orange"
                            class="custom-divider"
                            width="800px"
                        />
                    </v-col>
                </v-row>
                <v-row align="start">
                    <v-col cols="6" align="end">
                        <span class="font-weight-bold text-h6 custom-orange-text">Κινητό: </span>
                        <span class="font-weight-bold text-h6">+306979607540</span>
                    </v-col>
                    <v-col cols="6" align="start">
                        <span class="font-weight-bold text-h6 custom-orange-text">Email: </span>
                        <span class="font-weight-bold text-h6">gvalmas@backinthegame.gr</span>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped>
.custom-divider {
    border-width: 1px;
}
</style>
